.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.tab-list {
  border-bottom: 7px solid #272848;
  padding-left: 0;
}

.tab-content {
  margin: 2vw;
}

.tab-list-item {
  display: inline-block;
  background-color: #272848;
  color: #ffffff;
  width: 8vw;
  opacity: 60%;
  min-width: 120px;
  text-align: center;
  font-size: larger;
  font-weight: bolder;
  margin-left: 1vw;
  padding-top: 1.6vh;
  padding-bottom: 1.6vh;
  justify-content: center;
  align-items: center;
  list-style: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.tab-list-active {
  background-color: #272848;
  color: #ffffff;
  opacity: 100%;
}

.tab-list-first {
  margin-left: 2vw !important;
}

.adminDiv {
  text-align: start;
  padding-left: 1vw;
}

.alignLeft {
  text-align: start;
}

.align-items-center {
  align-items: center;
}

.adminHeader,
.landingWrapper {
  display: flex;
  flex-direction: column;
  margin-left: 2vw;
  margin-right: 2vw;
}

.landingWrapper,
.adminWrapper {
  height: 100vh;
}

.marginLeft {
  margin-left: 1vw;
}

.header {
  font-size: large;
  font-weight: bold;
}

.paddingBottom {
  padding-bottom: 1vh;
}

.marginBottom {
  margin-bottom: 1vh;
}

.loginBody {
  margin-top: 2vh;
}

.inputCont {
  width: 30vw;
  position: relative;
  display: flex;
  margin-bottom: 2vh;
  border: #61dafb 2px solid;
}

.input {
  background-color: #ffffff;
  font-size: large;
  width: 26vw;
  height: 4.5vh;
  border: none;
  margin: 0 auto;
  outline: none;
}

.AccessKeyInput {
  background-color: #ffffff;
  font-size: large;
  width: 26vw;
  height: 4.5vh;
  border: solid 1px #61dafb;
  text-align: center;
 
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

@keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
    font-size: inherit;
  }
}

@-webkit-keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
    font-size: inherit;
  }
}

.button {
  background-color: #54C1FB;
  border: none;
  color: white;
  font-size: 20px;
  padding: 10;
  border-radius: 25;
  margin-top: 20px;
  width: 300px;
  height: 50px;
  overflow: hidden;
}

.button:hover{
  background-color: #98d9fc;
}

.button:active{
  background-color: #6D71F9;
}

.adminButton {
  background-color: #6068be;
  border: none;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  padding: 10;
  padding-top: 15;
  border-radius: 25;
  margin-top: 22px;
  width: 300px;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  z-index: 11;
}

.deselectedAdminButton {
  background-color: #ffffff;
  border: 1px #6068be solid;
  color: black;
  font-size: 20px;
  font-weight: bold;
  padding: 10;
  padding-top: 15;
  border-radius: 25;
  margin-top: 22px;
  width: 300px;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  z-index: 11;
}

.eyeButton {
  background-color: transparent;
  border: none;
}

.disableDiv {
  padding-left: 3vw;
  margin-left: 1vw;
  align-items: center;
  align-content: center;
  text-align: center;
}

.resetDiv {
  padding-left: 3vw;
  margin-left: 1vw;
  align-items: center;
  text-align: center;
}

.deleteAccDiv {
  padding-left: 3vw;
  margin-left: 1vw;
  align-items: center;
  text-align: center;
}

.selectDiv {
  width: 25vw;
  text-align: start;
  margin-left: 12.5vw;
  padding-top: 5vh;
}

.select {
  width: 40%;
  height: 5vh;
  border-radius: 5px;
  border: 2px #6068be solid;
  font-size: large;
  margin-top: 0.5vh; 
}

.submitButton {
  background-color: #6068be;
  border: none;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  border-radius: 30px;
  margin-top: 22px;
  width: 40%;
  height: 6vh;
  overflow: hidden;
}
.submitButton:hover{
background-color: #9FA4D8;
}

.submitButton:active{
  background-color: #54C1FB;
  }

.submitLink {
  background-color: #6068be;
  border: none;
  text-align: center;
  padding-top: 10px;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  border-radius: 30px;
  margin-top: 22px;
  width: 40%;
  height: 4.2vh;
  overflow: hidden;
}
.adminText {
  font-size: larger;
}

@media screen and (max-width: 600px) {
  .landingHeader,
  .tab-content {
    margin-left: 5vw;
    margin-right: 5vw;
  }

  .inputCont {
    width: 75vw;
  }

  .input {
    width: 70vw;
  }

  .tab-list-item {
    font-size: x-small;
    width: 18vw;
    min-width: auto;
  }
}

.disabledDiv {
  padding: 5vw;
}
.deletedPage {
  padding: 10vw;
}

.redText {
  color: orangered;
}

.greenText {
  color: green;
}
