:root {
  --colour-white: #ffffff;
  --colour-black: #000000;
  --colour-darkBlue: #272848;
  --colour-lightBlue: #54C1FB;
  --colour-purple: #4E58A5;
  --colour-slateBlue: #6D71F9;
  --colour-lightGrey: #f8f8f8;
  --colour-grey: #808080;
  height: 100%;
}

/* Styling for App.tsx */
.App {
  text-align: center;

}

/* Styling for Header.tsx */
.intouch-logo {
  min-width: 100px;
  max-width: 6vw;
}

#intouch-title {
  color: var(--colour-darkBlue);
  font-size: 40px;
  padding-left: 2vh;
}


/* Styling for Body.tsx */
.intro-img {
  height: 60vh;
  flex: 1;
  resize: both;
  background-size: contain;
  margin-bottom: 15%;
  margin-left: 30%;
}

.landing-body {
  margin-top: 2vh;
}

.flex1 {
  flex: 1;
}

.flex025 {
  flex: 0.25;
}

.or-line {
  background-color: var(--colour-grey);
  height: 2px;
  width: 100px;
}

.or-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.or-text {
  color: var(--colour-grey);
  font-size: 2vh;
  margin-left: 1.5vw;
  margin-right: 1.5vw;
}

.landing-btn,
.login-btn {
  color: var(--colour-white);
  font-size: 2.5vh;
  padding: 1vh;
  border-radius: 25px;
  margin: 1vh;
  text-align: center;
  cursor: pointer;
}

.login-btn:hover{
  background-color: #7D7E91;
}

.login-btn:active{
  background-color: #272848;
}


.logout-btn{
  background-color: var(--colour-darkBlue);
  width: 200px;
  font-size: 15px;
  color: var(--colour-white);
  font-size: 2.5vh;
  padding: 1vh;
  border-radius: 25px;
  margin: 1vh;
  text-align: center;
  cursor: pointer;
  margin-left: 1325px;
}

.logout-btn:hover{
  background-color: #7D7E91;
}

.logout-btn:active{
  background-color: #272848;
}

.landing-btn {
  background-color: var(--colour-slateBlue);
  width: 300px;
}

.landing-btn:hover{
  background: #4E58A5;
}

.landing-btn:active{
  background: #54C1FB;
}


.login-btn {
  background-color: var(--colour-darkBlue);
  width: 200px;
}

a:link {
  text-decoration: none;
}

.login-link {
  margin-left: auto;
}

@media screen and (max-width: 950px) {
  .intro-img {
    height: 50vh;
    flex: 1 1;
    resize: both;
    background-size: contain;
    margin-bottom: 0;
    margin-left: 5%;
}
}

@media screen and (max-width: 600px) {
  .landing-body {
    flex-direction: column;
    align-content: center;
  }

  .divblock1 {
    margin-bottom: 100px;
    padding: 10px;
  }

  .intro-img {
    margin-left: 0;
  }


  #intouch-title {
    font-size: 30px;
  }

  .landing-btn,
  .login-btn {
    font-size: 15px;
  }

  #intro-header-image {
    height: 300px;
    width: 60%;
  }

  .intouch-logo {
    min-width: 80px;
    max-width: 6vw;

  }

  .intro-img {
    height: 40vh;
  }

  .login-btn {
    width: 100px;
  }
}

.footer{
  margin-top: 1rem;
  padding: 1rem;
  background-color: #272848;
  color: White;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.evologo {
  width: 20vh;
  margin: auto;
  display: block;
}

.spacer{
  border-left: 1px solid white;
  height: 15px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;

}

.footertext{
  width: 10%;
  margin: auto;
  display: flex;
  justify-content: center;
}

.link{
  margin-top: 15px;
  color: white;
}

.link:hover{
  color:#54C1FB ;
}

.link:active{
  color: #6D71F9;
}

.FQAContainer{
  width: 60%;
  text-align: center;
}

.FQADiv{
width: 200px;
float: left;

margin-left: 125px;
text-align: center;

}

.hline{
background-color: #272848;
width: auto;
height: 5px;
}

.FQAQuestion{
  color: #6D71F9;
}

.ConDiv{
  width: 200px;
  float: left;
  text-align: center;
  
  }

  .ConImg{
    width: 70%;
    margin-left: 50vh;
    margin-top: -400px;
    float: left;
  }


  .ConNameDiv{
    width: 500px;
    float: left;
    text-align: center;

  }

  .ConNameImg{
    width: 450px;
    height: 450px;
    border-radius: 50%;
    margin-top: 20px;
  }

  .ConNameImgSmall{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-top: 20px;
  }

  .ConName{
    font-size: 35px;
    font-weight: bold;
  }

  .ConNameDiv1{
    width: 800px;
    float: left;
    text-align: center;
    
    margin-left: 50px;

  }

  .ConNameDiv2{
    width: 1000px;
    float: left;
    text-align: center;
    margin-top: -400px;
    margin-left: 90vh;

  }

  .ReomoveConBox{
    background-color: #6D71F9;
    color: white;
  }

  .AddImg{
    width: 35%;
    float: left;
    margin-left: 225px;
    margin-top: -70px;
  }

  .Scroll{
    overflow-y: scroll;
    height:500px;
    width: fit-content;

  }

  .Scroll::-webkit-scrollbar {
    display: none;
  }

  .SupportScroll{
    overflow-y: scroll;
    width: fit-content;
  }

  .SupportScroll::-webkit-scrollbar {
    display: none;
  }

  .famScroll{
    overflow-y: scroll;
    width: fit-content;
    height: 200px;
    margin-left: 350px;

  }

  .famScroll::-webkit-scrollbar {
    display: none;
  }